import { useEffect, useState,useRef } from "react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { useSearchParams } from "react-router-dom";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import { supabaseClient , owner } from "../../supabase";
import { Card, CardContent, CardMedia } from "@mui/material";
import MDTypography from "components/MDTypography";
import ClipLoader from "react-spinners/ClipLoader";
import Modal from '@mui/material/Modal';

import MDButton from "components/MDButton";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import BlockIcon from '@mui/icons-material/Block';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Produit () {
    const [loading, setLoading] = useState(true);
    const [searchParams, setSearchParams] = useSearchParams();
    const [product,setProduct] = useState(undefined);

    const [open, setOpen] = useState(false);
    const [openNew, setOpenNew] = useState(false);
    const [openUpdateRef, setOpenUpdateRef] = useState(false);
    const [openDelete,setOpenDelete] = useState(false);
    const [openTransfert,setOpenTransfert] = useState(false);
    const [updateType,setUpdateType] = useState("name");
    const [showTransfert,setShowTransfert] = useState(false);

    const [locations,setLocations] = useState(new Set());
    const [fromLocation,setFromLocation] = useState();
    const [toLocation,setToLocation] = useState();
    const [depot,setDepot] = useState();
    const [depotAddr,setDepotAddr] = useState();
    const [stockDepot,setStockDepot] = useState([]);
    const [stockDepotTotal,setStockDepotTotal] = useState(0);
    const [rouleau,setRouleau] = useState();
    const [toDelete,setToDelete] = useState();

    const navigation = useNavigate();

    const reference = useRef(undefined);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOpenTransfert = () => setOpenTransfert(true);
    const handleCloseTransfert = () => setOpenTransfert(false);

    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const handleOpenUpdateRef = () => setOpenUpdateRef(true);
    const handleCloseUpdateRef = () => setOpenUpdateRef(false);

    const handleOpenNew = () => setOpenNew(true);
    const handleCloseNew = () => setOpenNew(false);
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: '#1a2035',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };


    const handleChange = (event) => {
        setFromLocation(event.target.value);
    };

    const handleChangeTarget = (event) => {
        setToLocation(event.target.value);
    };    

    const handleChangeTo = (event) => {
        setRouleau(event.target.value);
    };

    const handleNew = ()=>{
        supabaseClient.auth.getUser().then(({data,error})=>{
            if(error){
                navigation("/")
            }else{
                supabaseClient.from('Employees').select('id').eq("user_id",data.user.id).then(({data,error})=>{
                    if(error){
                        console.log(error)
                    }else{
                        //console.log("Employee",data)
                        const employee_id = data[0].id
                        
                        supabaseClient.from("Inventory").insert([{
                            product_id:searchParams.get('id'),
                            location_id:depot,
                            quantity:parseFloat(reference.current.value),
                            is_rouleau:true,
                        }]).select('id')
                        .then(({data:inventaire,error})=>{
                            if(error){
                                console.log(error)
                            }else{
                                supabaseClient.from("Inventory_Changes").insert([{
                                    inventory_id:inventaire[0].id,
                                    old_quantity:0,
                                    new_quantity:parseFloat(reference.current.value),
                                    status:"Execute",
                                    type:"arrivage",
                                    employee_id
                                }]).then(({data,error})=>{
                                    if(error){
                                        console.log(error)
                                    }else{
                                        toast("Stock Ajoute",{position:"top-center",type:"success"})
                                        handleCloseNew()
                                    }
                                })
                            }
                        })
                        
                    }

                })
                //console.log("From",fromLocation)
                
            }
        })
    }

    const handleMove = ()=>{
        supabaseClient.auth.getUser().then(({data,error})=>{
            if(error){
                navigation("/")
            }else{
                supabaseClient.from('Employees').select('id').eq("user_id",data.user.id).then(({data,error})=>{
                    if(error){
                        console.log(error)
                    }else{
                        const employee_id = data[0].id
                        console.log("Employee",data)
                        if(fromLocation.inventory === null){
                            supabaseClient.from("Inventory").insert([{
                                product_id:searchParams.get('id'),
                                location_id:fromLocation.id,
                                quantity:parseFloat(rouleau.quantity),
                                is_rouleau:false,
                            }]).select('id')
                            .then(({data:inventaire,error})=>{
                                if(error){
                                    console.log(error)
                                }else{

                                    supabaseClient.from("Inventory_Changes").insert([{
                                        inventory_id:inventaire[0].id,
                                        old_quantity:0,
                                        new_quantity:parseFloat(rouleau.quantity),
                                        status:"Execute",
                                        type:"arrivage",
                                        employee_id
                                    },{
                                        inventory_id:rouleau.id,
                                        old_quantity:rouleau.quantity,
                                        new_quantity:0,
                                        status:"Execute",
                                        type:"transfer",
                                        employee_id
                                    }]).then(({data,error})=>{
                                        if(error){
                                            console.log(error)
                                        }else{
                                            supabaseClient.from("Inventory").update({quantity:0}).eq("id",rouleau.id)
                                            .then(({data,error})=>{
                                                console.log('ERROR INV',error);
                                                console.log('DATA INV',data)
                                                toast(`Rouleau de ${rouleau.quantity} metres Transfere`,{position:"top-center",type:"success"})
                                                handleClose()
                                                   
                                            })
                                            
                                        }
                                    })
                                }
                            })
                        }else{
                            supabaseClient.from("Inventory_Changes").insert([{
                                inventory_id:fromLocation.inventory,
                                old_quantity:fromLocation.quantity,
                                new_quantity:parseFloat(fromLocation.quantity) + parseFloat(rouleau.quantity),
                                status:"Execute",
                                type:"transfer",
                                employee_id
                            },{
                                inventory_id:rouleau.id,
                                old_quantity:rouleau.quantity,
                                new_quantity:0,
                                status:"Execute",
                                type:"transfer",
                                employee_id
                            }]).then(({error})=>{
                                if(error){
                                    console.log(error)
                                }else{
                                    let newQuantFrom = parseFloat(fromLocation.quantity) + parseFloat(rouleau.quantity)       
                                    let product = searchParams.get('id')
                                    
                                    supabaseClient.from("Inventory").update({quantity:newQuantFrom}).select('id').eq('product_id',product).eq('location_id',fromLocation.id).then(({data,error})=>{console.log('ERROR INV',error);console.log('DATA INV',data)})
                                    supabaseClient.from("Inventory").update({quantity:0}).eq("id",rouleau.id).then(({data,error})=>{console.log('ERROR INV',error);console.log('DATA INV',data)})
                                    toast(`Rouleau de ${rouleau.quantity} metres Transfere`,{position:"top-center",type:"success"}) 
                                    handleClose()  
                                }
                            })
                        }
                    }

                })
                //console.log("From",fromLocation)
                
            }
        })

    }

    useEffect(()=>{
        supabaseClient.from('Products').select(`id,name,picture_id,type_tissu,prix_detail,prix_gros,Inventory(is_rouleau,id,location_id,quantity,reserved,Locations(id,address,type))`)
        .eq('id',searchParams.get('id')).eq('owner',owner)
        .then(({data,error})=>{
            if(error){
                console.log('Error',error)
            }else{
                setProduct(data[0]);
                setLoading(false)
                console.log(data[0])
                let location_ids = []
                let locs = new Set()//[]
                let rouleaux = []
                data[0].Inventory.forEach((value)=>{
                    location_ids.push(value.location_id)
                    if(value.Locations.type === "wearhouse"){
                        if(!depot){
                            setDepot(value.location_id)
                            setDepotAddr(value.Locations.address)
                        }
                        if(value.quantity > 0){
                            rouleaux.push(value)
                        }
                        
                    }else{
                        if (!showTransfert && value.quantity > 0) setShowTransfert(true)
                    } 
                    
                    locs.add({...value.Locations,quantity:value.quantity,inventory:value.id,invExist:true})
                })
                console.log("Locs",location_ids)
                setStockDepot(rouleaux)
                setStockDepotTotal(rouleaux.reduce((total,value)=>{
                    return total + value.quantity
                },0))
                supabaseClient.from('Locations').select("id,address,type").eq("owner",owner).then(({data,error})=>{
                    let copyLocs = new Set(locs)
                    data.forEach((value)=>{
                        if(value.type === "wearhouse" && !depot){
                            setDepot(value.id)
                            setDepotAddr(value.address)
                        }
                        if(location_ids.indexOf(value.id) === -1){
                            copyLocs.add({...value,quantity:0,inventory:null,invExist:false})
                        }
                    })
                    console.log('Copy',copyLocs)
                    setLocations(copyLocs)
                })
                
            }
        })
    },[open,openNew,openUpdateRef,openDelete,openTransfert])

    const handleUpdate = ()=>{
        let update = {}
        if(updateType === "name"){
            update[updateType]=reference.current.value
        }else{
            update[updateType]=parseFloat(reference.current.value)
        }
        console.log("update",update)
        supabaseClient.from('Products').update(update).eq('id',searchParams.get('id'))
        .then(({data,error})=>{
            if(error){
                console.log(error)
            }else{
                toast(`${getupdateType()} mis a jour !`,{position:"top-center",type:"success"})
                handleCloseUpdateRef()
            }
        })
    }
    
    const getupdateType = ()=>{
        switch (updateType) {
            case "name":
            default:
                return  "Reference"
            case "prix_gros":
                return "Prix Grossiste"
            case "prix_detail":
                return "Prix Detail"
            
        }
    }

    const getupdateTypeInput = ()=>{
        switch (updateType) {
            case "name":
                return  ""
            default:
                return  "number"
        }
    }

    const handleDelete = ()=>{
        supabaseClient
        .from('Inventory_Changes')
        .delete()
        .eq('inventory_id',stockDepot[toDelete].id).then(()=>{
            supabaseClient.from('Inventory').delete().eq("id",stockDepot[toDelete].id).then(()=>{
                toast(`Rouleau de ${stockDepot[toDelete].quantity} metres Supprime!`,{type:"success"})
                handleCloseDelete()
            })
        })
    }

    const handleTransfert = ()=>{
        if (parseFloat(reference.current.value) <= parseFloat(fromLocation.quantity)){
            supabaseClient.auth.getUser().then(({data,error})=>{
                if(error){
                    navigation("/")
                }else{
                    supabaseClient.from('Employees').select('id').eq("user_id",data.user.id).then(({data,error})=>{
                        if(error){
                            console.log(error)
                        }else{
                            const employee_id = data[0].id
                            console.log("Employee",data)
                            if(toLocation.inventory === null){
                                supabaseClient.from("Inventory").insert([{
                                    product_id:searchParams.get('id'),
                                    location_id:toLocation.id,
                                    quantity:parseFloat(reference.current.value),
                                    is_rouleau:false,
                                }]).select('id')
                                .then(({data:inventaire,error})=>{
                                    if(error){
                                        console.log(error)
                                    }else{

                                        supabaseClient.from("Inventory_Changes").insert([{
                                            inventory_id:inventaire[0].id,
                                            old_quantity:0,
                                            new_quantity:parseFloat(reference.current.value),
                                            status:"Execute",
                                            type:"transfer",
                                            employee_id
                                        },{
                                            inventory_id:fromLocation.inventory,
                                            old_quantity:parseFloat(fromLocation.quantity) ,
                                            new_quantity:parseFloat(fromLocation.quantity) - parseFloat(reference.current.value),
                                            status:"Execute",
                                            type:"transfer",
                                            employee_id
                                        }]).then(({data,error})=>{
                                            if(error){
                                                console.log(error)
                                            }else{
                                                supabaseClient.from("Inventory").update({quantity:parseFloat(fromLocation.quantity) - parseFloat(reference.current.value)}).eq("id",fromLocation.inventory)
                                                .then(({data,error})=>{
                                                    console.log('ERROR INV',error);
                                                    console.log('DATA INV',data)
                                                    toast(`${reference.current.value} metres Transfere à ${toLocation.address}`,{position:"top-center",type:"success"})
                                                    handleCloseTransfert()
                                                    
                                                })
                                                
                                            }
                                        })
                                    }
                                })
                            }else{
                                supabaseClient.from("Inventory_Changes").insert([{
                                    inventory_id:fromLocation.inventory,
                                    old_quantity:fromLocation.quantity,
                                    new_quantity:parseFloat(fromLocation.quantity) - parseFloat(reference.current.value),
                                    status:"Execute",
                                    type:"transfer",
                                    employee_id
                                },{
                                    inventory_id:toLocation.inventory,
                                    old_quantity:parseFloat(toLocation.quantity) + parseFloat(reference.current.value),
                                    new_quantity:0,
                                    status:"Execute",
                                    type:"transfer",
                                    employee_id
                                }]).then(({error})=>{
                                    if(error){
                                        console.log(error)
                                    }else{
                                        supabaseClient.from("Inventory").update({quantity:parseFloat(toLocation.quantity) + parseFloat(reference.current.value)}).eq('id',toLocation.inventory).then(({data,error})=>{console.log('ERROR INV',error);console.log('DATA INV',data)})
                                        supabaseClient.from("Inventory").update({quantity:parseFloat(fromLocation.quantity) - parseFloat(reference.current.value)}).eq("id",fromLocation.inventory).then(({data,error})=>{console.log('ERROR INV',error);console.log('DATA INV',data)})
                                        toast(`${reference.current.value} metres Transfere à ${toLocation.address}`,{position:"top-center",type:"success"})
                                        handleCloseTransfert()  
                                    }
                                })
                            }
                        }

                    })
                    //console.log("From",fromLocation)
                    
                }
        })}else{
            toast(`${reference.current.value} metres n'est pas disponible !\n Max : ${fromLocation.quantity} `,{position:"top-center",type:"warning"})
        }
    }

    return(
        <DashboardLayout>
            <ToastContainer/>
            <DashboardNavbar/>
                <div style={{textAlign:"center"}}>
                <MDTypography sx={{justifyContent:"center"}} variant="h1" fontWeight="regular" color="white" mt={15} >
                                    Inventaire
                </MDTypography>
                </div>
                <div style={{justifyContent:"center",display:"flex",marginTop:"5%"}}>
                        
                        <br/>
                        {loading?<div style={{justifyContent:"center"}}>
                            <ClipLoader
                                color={"black"}
                                loading={loading}
                                size={150}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>:<>
                        <Card sx={{width:"80%"}}>
                            <CardMedia
                            sx={{height:200,display:"flex"}}
                            image={`https://eowoxbnqflkekcpqajvg.supabase.co/storage/v1/object/public/images/${product.picture_id}`} 
                            />
                            <CardContent sx={{display:"block",justifyContent:"space-between"}}>
                                <div style={{display:"inline-block",justifyContent:"space-between"}}>
                                <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                    Reference : {product.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <MDButton onClick={()=>{
                                        setUpdateType("name")
                                        handleOpenUpdateRef()}} variant="gradient" color="info"   size="medium" mt={1} >
                                    <EditIcon fontSize="large" sx={{color:'white'}}/>
                                    </MDButton>
                                </MDTypography>
                                
                                </div>
                                
                                <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                    Type : {product.type_tissu} 
                                </MDTypography>
                                <div style={{display:"inline-block",justifyContent:"space-between"}}>
                                <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                    Prix details : {product.prix_detail} DH(s)/m&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <MDButton onClick={()=>{
                                        setUpdateType("prix_detail")
                                        handleOpenUpdateRef()}} variant="gradient" color="info"   size="medium" mt={1} >
                                    <EditIcon fontSize="large" sx={{color:'white'}}/>
                                    </MDButton>
                                </MDTypography>
                                
                                </div>
                                <br/>
                                <div style={{display:"inline-block",justifyContent:"space-between"}}>
                                <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                    Prix grossiste : {product.prix_gros} DH(s)/m&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <MDButton onClick={()=>{
                                        setUpdateType("prix_gros")
                                        handleOpenUpdateRef()}} variant="gradient" color="info"   size="medium" mt={1} >
                                    <EditIcon fontSize="large" sx={{color:'white'}}/>
                                    </MDButton>
                                </MDTypography>
                                
                                </div>
                                {product.Inventory.map((value,index)=>{
                                    if(parseFloat(value.quantity) - parseFloat(value.reserved) > 0 && !value.is_rouleau )
                                    return(
                                        <div key={index}>
                                            <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                                Addresse : {value.Locations.address} 
                                            </MDTypography>
                                            <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} ml={10} >
                                                Quantite : {parseFloat(value.quantity) - parseFloat(value.reserved)} m(s) 
                                            </MDTypography>
                                        </div>
                                    )
                                })}
                                {(stockDepot.length !== 0)?<>
                                    <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                                        Addresse :  {depotAddr} 
                                    </MDTypography>
                                    <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} ml={10}>
                                        Total :  {stockDepotTotal} m(s) 
                                    </MDTypography>
                                {
                                    stockDepot.map((value,index)=>{
                                        return(
                                            <div key={index}>
                                                <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} ml={10} >
                                                    {`Rouleau de ${value.quantity} metres`} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <MDButton onClick={()=>{
                                                        setToDelete(index)
                                                        handleOpenDelete()}} variant="gradient" color="info"   size="medium" mt={1} ml={10}>
                                                    <DeleteForeverIcon fontSize="large" sx={{color:'white'}}/>
                                                    </MDButton>
                                                </MDTypography>
                                            </div>
                                        )
                                    })
                                }</>:<></>}

                            </CardContent>
                            
                        </Card>
                        
                        
                        </>}
                </div>
                {(product)?<>
                {(stockDepot.length !== 0)?
                <div style={{alignContent:"center",alignItems:"center",display:"flex",justifyContent:"center",margin:"5%"}}>
                    <MDButton onClick={handleOpen} variant="gradient" color="info"   size="large" mt={5}>
                                Gerer l&apos;inventaire depuis le dépot
                    </MDButton>
                    
                </div>:<></>}
                {(showTransfert)?
                <div style={{alignContent:"center",alignItems:"center",display:"flex",justifyContent:"center",margin:"5%"}}>
                <MDButton onClick={handleOpenTransfert} variant="gradient" color="info"   size="large" mt={5}>
                            Transferer l&apos;inventaire entre magasin
                </MDButton>
                
                </div>:<></>}
                
                </>:<></>}
                <div style={{alignContent:"center",alignItems:"center",display:"flex",justifyContent:"center",margin:"5%"}}>
                    <MDButton onClick={handleOpenNew} variant="gradient" color="info"   size="large" mt={5}>
                                Nouveau Arrivage
                    </MDButton>
                </div>
                <Modal
                    open={openTransfert}
                    onClose={handleCloseTransfert}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
                >
                    
                    <Box sx={{...style,minWidth: 120,display:"list-item",height:500,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                        
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"space-evenly"}}>
                            <FormControl  sx={{minHeight:100}}>
                                <InputLabel id="demo-simple-select-label">Magasin de depart</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fromLocation}
                                    label="Magasin de depart"
                                    
                                    onChange={handleChange}
                                    sx={{minHeight:80,minWidth:300}}
                                >
                                    
                                    {(locations.size !== 0)?Array.from(locations).map((value,index)=>{
                                        
                                            if(value.id !== depot && value.quantity > 0){
                                        return(
                                            <MenuItem key={index} value={value}>{`${value.address} : ${value.quantity} m(s)`} </MenuItem>)
                                    }}):<></>}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"center"}}>
                            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                            Quantite :
                            </MDTypography>
                            <MDBox m={2}>
                            <MDInput type="number" inputRef={reference}  fullWidth />
                            </MDBox>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"space-evenly"}}>
                            <FormControl  sx={{minHeight:100}}>
                                <InputLabel id="demo-simple-select-label">Magasin d&apos;arriver</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={toLocation}
                                    label="Magasin d'arriver"
                                    
                                    onChange={handleChangeTarget}
                                    sx={{minHeight:80,minWidth:300}}
                                >
                                    
                                    {(locations.size !== 0)?Array.from(locations).map((value,index)=>{
                                        
                                            if(value.id !== depot && fromLocation !== undefined){
                                                
                                                if (value.id !== fromLocation.id) return(
                                                    <MenuItem key={index} value={value}>{value.address} </MenuItem>
                                                )
                                            }
                                        }):<></>
                                    }
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"center"}}>
                            <MDButton onClick={handleTransfert} variant="gradient" color="info"  size="large">
                                Transferer le stock
                            </MDButton>
                        </Box>
                    </Box>
                    
                </Modal> 
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
                >
                    
                    <Box sx={{...style,minWidth: 120,display:"list-item",height:500,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                        
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"space-evenly"}}>
                            <FormControl  sx={{minHeight:100}}>
                                <InputLabel id="demo-simple-select-label">Magasin d&apos;arriver</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={fromLocation}
                                    label="Magasin de depart"
                                    
                                    onChange={handleChange}
                                    sx={{minHeight:80,minWidth:300}}
                                >
                                    
                                    {(locations.size !== 0)?Array.from(locations).map((value,index)=>{
                                        
                                            if(value.id !== depot){
                                        return(
                                            <MenuItem key={index} value={value}>{value.address} </MenuItem>)
                                    }}):<></>}
                                </Select>
                            </FormControl>
                        </Box>
                        <br/>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"center"}}>
                            <FormControl  sx={{minHeight:100}}>
                                <InputLabel id="demo-simple-select-label">Rouleau</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={rouleau}
                                    label="Rouleau"
                                    
                                    onChange={handleChangeTo}
                                    sx={{minHeight:80,minWidth:300}}
                                >
                                    {(stockDepot.length !== 0)?stockDepot.map((value,index)=>{
                                        return(
                                            <MenuItem key={index} value={value}>{`Rouleau de ${value.quantity} metres`}</MenuItem>)
                                    }):<></>}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"center"}}>
                            <MDButton onClick={handleMove} variant="gradient" color="info"  size="large">
                                Transferer le stock
                            </MDButton>
                        </Box>
                    </Box>
                    
                </Modal> 
                <Modal
                    open={openNew}
                    onClose={handleCloseNew}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
                >
                    
                    <Box sx={{...style,minWidth: 120,display:"list-item",height:500,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                        <br/>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"center"}}>
                            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                            Quantite :
                            </MDTypography>
                            <MDBox m={2}>
                            <MDInput type="number" inputRef={reference}  fullWidth />
                            </MDBox>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"space-evenly"}}>
                            <MDButton onClick={handleNew} variant="gradient" color="info"   size="large"  >
                                Receptioner le stock
                            </MDButton>
                        </Box>
                    </Box>
                    
                </Modal> 
                <Modal
                    open={openUpdateRef}
                    onClose={handleCloseUpdateRef}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
                >
                    <Box sx={{...style,minWidth: 120,display:"list-item",height:500,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                        <br/>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"space-evenly"}}>
                            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                                {getupdateType()} :
                            </MDTypography>
                            
                            <MDBox m={2}>
                            <MDInput type={getupdateTypeInput()} inputRef={reference}  fullWidth />
                            </MDBox>
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"space-evenly"}}>
                            <MDButton onClick={handleUpdate} variant="gradient" color="info"   size="large"  >
                                Mettre a jour {getupdateType()}
                            </MDButton>
                        </Box>
                    </Box>
                    
                </Modal>
                <Modal
                    open={openDelete}
                    onClose={handleCloseDelete}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
                >
                    <Box sx={{...style,minWidth: 120,display:"list-item",height:500,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                        <br/>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"space-evenly"}}>
                            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                                Supprimer Ce Rouleau ?
                            </MDTypography>
                            
                            
                        </Box>
                        <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:10,justifyContent:"space-evenly"}}>
                            <MDButton onClick={handleDelete} variant="gradient" color="info"   size="large" sx={{margin:5}} >
                                OUI
                                <CheckCircleIcon fontSize="large" sx={{color:'white'}}/>
                            </MDButton>
                            <MDButton onClick={handleCloseDelete} variant="gradient" color="info"   size="large" sx={{margin:5}} >
                                NON
                                <BlockIcon fontSize="large" sx={{color:'white'}}/>
                            </MDButton>
                        </Box>
                    </Box>
                    
                </Modal>
        </DashboardLayout>
    )




}

export default Produit;