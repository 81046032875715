import { useState, useEffect } from "react";

import {supabaseClient} from "../../supabase"
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Box from '@mui/material/Box';
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import { CardActions, CardContent, CardMedia } from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Commandes = ()=>{
    const [blockedOrders,setBlockedOrders] = useState([])
    const [currentOrder,setCurentOrder] = useState()
    const [refresh,setRefresh] = useState(true)

    const handleValidate = ()=>{
        console.log("Current",blockedOrders[currentOrder])
        let status = 'Tissu à préparer'
        if(blockedOrders[currentOrder].avance === null){
            status =  'Avance'
        }else{
            if(((blockedOrders[currentOrder].type === 'Salon Trad')||(blockedOrders[currentOrder].type === 'Rideaux'))&& !blockedOrders[currentOrder].mesure_included){
                status = 'Mesures'
            }
        }
        supabaseClient.from('Orders').update({ status })
        .eq('id', blockedOrders[currentOrder].id).then(({error})=>{
            setRefresh(true)
            console.log('Error Orders Update',error)
        })
        

    }
    const handleDelete = ()=>{
        supabaseClient.from("Orders").delete()
        .eq('id', blockedOrders[currentOrder].id)
        .then(({error})=>{
            setRefresh(true)
            console.log('Error Orders Delete',error)
        })
    }
    useEffect(()=>{
        if(refresh){
            supabaseClient.from('Orders')
            .select("id,client_name,avance,type,mesures_included,Locations(address),Employees(name)")
            .eq("status","Bloquée")
            .then(({data,error})=>{
                if(error){
                    console.log("Error",error)
                }else{
                    console.log('data',data)
                    setBlockedOrders(data)
                }
            })
            setRefresh(false)
        }

    },[refresh])
    return(
        <DashboardLayout>
            <DashboardNavbar/>
            {(blockedOrders.length === 0)?<Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,width:"100%",justifyContent:"center"}}>
                <MDTypography variant="h3" fontWeight="regular" color="white" m={2} >
                Pas de Commandes Bloquées !
                </MDTypography>
            </Box>:
            <Box sx={{alignContent:'center',alignItems:'center',display:'block',margin:2,justifyContent:"center",textAlign:"center"}}>
                <MDTypography variant="h3" fontWeight="regular" color="white" m={2} >
                Commandes Bloquées : {blockedOrders.length}
                </MDTypography>
                <br/>
                {blockedOrders.map((value,index)=>{
                return(
                <Card key={index} sx={{minWidth:200,margin:10}}>
                    <CardContent>
                    <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                        Nom du Client : {value.client_name} 
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                        Magasin : {value.Locations.address} 
                    </MDTypography>
                    <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                        Employe : {value.Employees.name} 
                    </MDTypography>
                    
                    </CardContent>
                    <CardActions sx={{alignItems:'center',justifyContent:'space-evenly'}}>
                        <MDButton onClick={()=>{setCurentOrder(index)
                            handleValidate()}} variant="gradient" color="info"   size="large" sx={{margin:5}} >
                            Valider
                            <CheckCircleIcon fontSize="large" sx={{color:'white'}}/>
                        </MDButton>
                        <MDButton onClick={()=>{setCurentOrder(index)
                            handleDelete()}} variant="gradient" color="info"   size="large" sx={{margin:5}} >
                            Annuler 
                            <DeleteForeverIcon fontSize="large" sx={{color:'white'}}/>
                        </MDButton>
                    </CardActions>
                </Card>)   
                })}
            </Box>}
        </DashboardLayout>
    )
}

export default Commandes;