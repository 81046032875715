/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState,useRef } from "react";

// react-router-dom components
import { Link,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Box from '@mui/material/Box';
// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";


import { supabaseClient } from "../../../supabase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Basic() {
  const email = useRef(undefined)
  const password = useRef(undefined)
  //const [password, setPassword] = useState(undefined)
  //const [email, setEmail] = useState(undefined)
  const [rememberMe, setRememberMe] = useState(false);
  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  function handleSubmit (){
    console.log('hereeeeeee')
    console.log('Email',password.current.value)
    supabaseClient.auth.signInWithPassword({
      email: email.current.value,
      password: password.current.value,
    }).then(({ error,data })=>{
      if(error === null) {
        if(data.user.app_metadata.type){
          if(data.user.app_metadata.type === 'owner'){
            navigate("/dashboard") 
          }
          if(data.user.app_metadata.type === 'admin'){
            navigate("/commandes") 
          }
    
        }else{
          navigate('/')
        }
        
      } 
      console.log('Data',data)
      console.log('Error',error)
    })
  }
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1} >
            Sign in
          </MDTypography>
          
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput inputRef={email} type="email" label="Email" fullWidth />
            </MDBox>
            <MDBox mb={2}>
              <MDInput inputRef={password} type="password" label="Password" fullWidth />
            </MDBox>
            
            <MDBox mt={4} mb={1}>
              <MDButton onClick={handleSubmit} variant="gradient" color="info"  fullWidth>
                sign in
              </MDButton>
            </MDBox>
            
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
