/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from 'react';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { MuiFileInput } from 'mui-file-input';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { supabaseClient ,owner} from "../../supabase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toCanvas } from "qrcode";
import jsPDF from 'jspdf';
import SearchIcon from '@mui/icons-material/Search';
import DownloadIcon from '@mui/icons-material/Download';
import { CardActions, CardContent, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

function Charges() {

  const CHARGES = ['Salaires','Transport','Loyer','Eau & Electricité','Frais Divers','Passemontré','Fournisseur']

  const navigation = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [file,setFile] = React.useState(undefined);
  const [typecharge,setTypeCharges] = React.useState();
  const reference = React.useRef(undefined);
  const total = React.useRef(undefined);
  const prixGros = React.useRef(undefined);
  const searchInput = React.useRef(undefined);
  const qrRef = React.useRef(null);
  const [searchData,setSearchData] = React.useState([])
  const [onDelete,setOnDelete] = React.useState(false)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileSelect = (newValue)=>{
    setFile(newValue);
  }

  const handleTypeInput = (ev)=>{
    console.log(ev.target.value)
    setTypeCharges(ev.target.value)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: '#1a2035',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };


  function getStartingDateOfCurrentMonth() {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Months are zero-based, so we add 1
    const startingDate = new Date(`${year}-${month.toString().padStart(2, '0')}-01`);
  
    return startingDate.toISOString().split('T')[0];
  }

  React.useEffect(()=>{
    supabaseClient.auth.getUser().then(({data,error})=>{
      if(error){
          navigation("/")
      }else{
          supabaseClient.from('Employees').select('id').eq("user_id",data.user.id).then(({data:employee,error})=>{
            supabaseClient.from('Charges').select('*').eq('owner',owner).gte('created_at',getStartingDateOfCurrentMonth()).order('created_at', { ascending: false })
            .then(({data:charges,error})=>{
              if(error){
                console.log('Error fetching',error)
              }else{
                
                console.log('Data',charges)
                setSearchData(charges)
                if (onDelete) setOnDelete(false)
              }
            })
          })
      }
    })
  },[open,onDelete])

  const handleAdd = ()=>{
    const currentDate = new Date();
    supabaseClient.auth.getUser().then(({data,error})=>{
      if(error){
          navigation("/")
      }else{
          supabaseClient.from('Employees').select('id').eq("user_id",data.user.id).then(async ({data:employee,error})=>{
            if(error){
              console.log('Error fetching',error)
            }else{
              let row = {
                total:total.current.value,
                type:typecharge,
                commentaire:reference.current.value,
                owner:owner,
                employee:employee[0].id,
                justification:null,
              }
              if (file){
                await supabaseClient.storage.from('charges')
                .upload(`/justif_${currentDate.toISOString()}_${total.current.value}.jpg`,file)
                row.justification = `/justif_${currentDate.toISOString()}_${total.current.value}.jpg`
              }
              supabaseClient.from('Charges').insert([row])
              .select('*')
              .then(({data:charges,error})=>{
                if(error){
                  console.log('Error fetching',error)
                }else{
                  
                  console.log('Data',charges)
                  toast(`Charge de ${total.current.value} DH(s) ajoutée !`,{position:"top-center",type:"success"})
                  handleClose()
                }
              })
            }
            
          })
      }
    })


  }

  const deleteCharge = (id)=>{
    
    supabaseClient.from('Charges').delete().eq('id',id).then(({data,error})=>{
      if(error){
        console.log('Error Deleting',error)
      }else{
        setOnDelete(true)
      }
    })
  }

  return (
    <DashboardLayout>
      <ToastContainer/>
      <DashboardNavbar/>
      <MDBox pt={6} pb={3}>
        <div style={{alignContent:"center",alignItems:"center",display:"flex",justifyContent:"center",margin:"5%"}}>
            <MDButton onClick={handleOpen} variant="gradient" color="info"   size="large" mt={5}>
                        Nouvelle Charge
            </MDButton>
            
        </div>
        <div style={{justifyContent:"center",alignContent:"center",display:"inline-block",width:"100%",textAlign:"center"}}>
          {(searchData.length > 0)?
          <MDTypography variant="h3" fontWeight="regular" color="white" mt={5} >
                  Les charges de ce mois :
          </MDTypography>:<></>}   
          
          <div style={{alignItems:'center',justifyContent:'center',display:"grid",margin:20,gridAutoFlow:"row"}}>
            {searchData.map((value,index)=>{
              return(
              <Card key={index} sx={{minWidth:200,margin:10}}>
                {(value.justification)?<CardMedia
                            sx={{height:200,display:"flex"}}
                            image={`https://eowoxbnqflkekcpqajvg.supabase.co/storage/v1/object/public/charges/${value.justification}`} 
                            />:<></>}
                <CardContent>
                  <MDTypography variant="h4" fontWeight="regular" color="white" mt={1} >
                    Total : {value.total} 
                  </MDTypography>
                  <MDTypography variant="h4" fontWeight="regular" color="white" mt={1} >
                    Type : {value.type} 
                  </MDTypography>
                  <MDTypography variant="h7" fontWeight="regular" color="white" mt={1} >
                    Commentaire : {value.commentaire}  
                  </MDTypography>
                </CardContent>
                <CardActions sx={{alignItems:'center',justifyContent:'center'}}>
                  <MDButton onClick={()=>{deleteCharge(value.id)}} variant="gradient" color="info"  size="medium">
                    <DeleteForeverIcon fontSize="large" sx={{color:'white'}}/>
                  </MDButton>
                </CardActions>
              </Card>)   
          })}
          </div>
          
        </div>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{display:"block",justifyContent:"center",alignContent:"center",alignItems:"center"}}
        >
          <Box sx={{...style,minWidth: 120,display:"block",height:700,justifyContent:"center",alignContent:"center",alignItems:"center"}}>
                    
            <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2,justifyContent:"center",width:"100%"}}>
              <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
                Justificatif : 
              </MDTypography>
              <MuiFileInput inputProps={{accept:"image/*", capture:"environment"}} style={{margin:20,alignSelf:'center',backgroundColor:'white',border: '2px solid #000',}} value={file} onChange={handleFileSelect} />
            </Box>
            <br/>
            <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2,justifyContent:"center",width:"100%"}}>
              <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                Total :
              </MDTypography>
              <MDBox m={2}>
                <MDInput type="number" inputRef={total}  fullWidth />
              </MDBox>
            </Box>
            <br/>
            <Box sx={{alignContent:'center',alignItems:'center',display:'flex',margin:2,justifyContent:"space-evenly"}}>
              <FormControl  sx={{minHeight:100}}>
                  <InputLabel id="demo-simple-select-label">Type de Charge</InputLabel>
                  <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={typecharge}
                      label="Type de Charge"
                      
                      onChange={handleTypeInput}
                      sx={{minHeight:80,minWidth:300}}
                  >
                      
                      {CHARGES.map((value,index)=>{
                          
                               return(
                                      <MenuItem key={index} value={value}>{value} </MenuItem>
                                  )
                              })}
                  </Select>
              </FormControl>
            </Box>
            <br/>
            <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2,justifyContent:"center",width:"100%"}}>
              <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                Commentaire :
              </MDTypography>
              <MDBox m={2}>
                <MDInput multiline type='text' inputRef={reference} minRows={4} fullWidth />
              </MDBox>
            </Box> 
            <br/> 
            <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2,justifyContent:"space-evenly",width:"100%"}}>
              <MDButton onClick={handleAdd} variant="gradient" color="info"   size="large">
                Ajouter Charge
              </MDButton> 
            </Box>  
          </Box>
                       
        </Modal>
      </MDBox>
    </DashboardLayout>
  );
}

export default Charges;
