/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import * as React from 'react';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { MuiFileInput } from 'mui-file-input';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { supabaseClient ,owner} from "../../supabase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toCanvas } from "qrcode";
import jsPDF from 'jspdf';
import SearchIcon from '@mui/icons-material/Search';
import { CardActions, CardContent, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {Table,
  TableRow,
  TableHead,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Typography, 
  IconButton, 
}from '@mui/material'
function Tables() {
  const navigation = useNavigate()
  const [open, setOpen] = React.useState(false);
  const [file,setFile] = React.useState(undefined);
  const [typeTissu,setTypeTissu] = React.useState("salon");

  const reference = React.useRef(undefined);
  const prixDetails = React.useRef(undefined);
  const prixGros = React.useRef(undefined);
  const searchInput = React.useRef(undefined);
  const qrRef = React.useRef(null);
  const [searchData,setSearchData] = React.useState([])

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleFileSelect = (newValue)=>{
    setFile(newValue);
  }

  const handleTypeInput = (ev)=>{
    console.log(ev.target.value)
    setTypeTissu(ev.target.value )
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    bgcolor: '#1a2035',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handleSearchInput = ()=>{
    if(searchInput.current.value){
      supabaseClient.from('Products').select('*,Inventory(is_rouleau,id,location_id,quantity,reserved)')
      .textSearch('name',`'${searchInput.current.value}'`).eq('owner',owner)
      .then(({data,error})=>{
        if(error){
          console.log('Error fetching',error)
        }else{
          console.log('Data',data)
          setSearchData(data)
        }
      })
    }
  }

  const generateQR = async (productID,ref)=>{
    await toCanvas(qrRef.current, productID,{scale:6});
    //const canvas = await html2canvas(qrRef.current);
  
    // Get the image data
    const imgData = qrRef.current.toDataURL('image/jpeg');//canvas.toDataURL('image/png');
    // Create a new PDF
    const pdf = new jsPDF();
    
    // Add the image to the PDF
    pdf.setLineWidth(2)
    pdf.rect(5,5,200,75)
    pdf.addImage(imgData, 'PNG', 15, 10);
    pdf.text(`Reference : ${ref}`,90,45)
    // Save the PDF
    pdf.save(`${ref}.pdf`);

  }

  const handleAdd = ()=>{
    supabaseClient.storage.from('images').upload(`/${reference.current.value}.jpg`,file)
    .then(({data,error})=>{
      console.log('error Upload',error)
      console.log('Data',data)
      supabaseClient.from('Products').insert([{
        name:reference.current.value,
        picture_id:`${reference.current.value}.jpg`,
        prix_detail:parseFloat(prixDetails.current.value),
        prix_gros:parseFloat(prixGros.current.value),
        type_tissu:typeTissu,
        owner:owner,
      }]).select('id,name').then(({data,error})=>{
        console.log('error Insert',error)
        console.log('Data Insert',data)
        if(error === null){ 
          toast('Produit Ajoute !',{position:"top-center",type:"success"})
          handleClose()
          generateQR(data[0].id,data[0].name)
          navigation(`/produit?id=${data[0].id}`)
        }
      })
    })
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - searchData.length) : 0;

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleChangePage = (event, newPage) => {
      setPage(newPage);
  };



  return (
    <DashboardLayout>
      <div style={{ display: "none" }}>
        <canvas ref={qrRef} />
      </div>
      <ToastContainer/>
      <DashboardNavbar/>
      <MDBox pt={6} pb={3}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
       
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
        <Box sx={style}>
          <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2}}>
            <MDTypography variant="h6" fontWeight="regular" color="white" mt={1} >
              Image du Tissu : 
            </MDTypography>
            <MuiFileInput style={{margin:20,alignSelf:'center',backgroundColor:'white',border: '2px solid #000',}} value={file} onChange={handleFileSelect} />
          </Box>
          <br/>
          <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2}}>
            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
              Reference :
            </MDTypography>
            <MDBox m={2}>
              <MDInput inputRef={reference}  fullWidth />
            </MDBox>
          </Box>
          <br/>
          <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2}}>
            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
              Prix Details :
            </MDTypography>
            <MDBox m={2}>
              <MDInput type="number" inputRef={prixDetails}  fullWidth />
            </MDBox>
          </Box>
          <br/>
          <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2}}>
            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
              Prix Grossiste (Prix Rouleau) :
            </MDTypography>
            <MDBox m={2}>
              <MDInput type="number" inputRef={prixGros}  fullWidth />
            </MDBox>
          </Box>
          <br/>
          <Box sx={{alignContent:'center',alignItems:'center',display:'inline-flex',margin:2}}>
            <MDTypography variant="h6" fontWeight="regular" color="white" m={2} >
                Type :
            </MDTypography>
            <FormControl>
              <RadioGroup
                onChange={handleTypeInput}
                defaultValue={"salon"}
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel value="rideau" control={<Radio />} label="Rideaux" />
                <FormControlLabel value="salon" control={<Radio />} label="Salon" />
              </RadioGroup>
            </FormControl>
          </Box>
          <MDButton onClick={handleAdd} variant="gradient" color="info"  fullWidth size="large">
            Ajouter Produit
          </MDButton>
        </Box>
        </MDBox>
      </Modal>
        <div style={{justifyContent:"center",alignContent:"center",display:"inline-grid",width:"100%"}}>
              <div style={{display:'inline-flex',alignItems:'center',justifyContent:'center',marginBottom:"10%"}}>
                <MDButton onClick={handleOpen} variant="gradient" color="info" size="large">
                  Ajouter Produit
                </MDButton>
              </div>
              <br/>
              <div style={{display:'inline-flex',alignItems:'center',justifyContent:'center',marginBottom:"10%"}}>
                <MDInput inputRef={searchInput}  label='Reference' fullWidth style={{marginRight : 10}}/>
                <MDButton onClick={handleSearchInput} variant="gradient" color="info"   size="large">
                  <SearchIcon fontSize="large" sx={{color:'white'}}/>
                </MDButton>
              </div>
          
          <div style={{alignItems:'center',justifyContent:'center',display:"block",margin:20}}>
          <TableContainer >
                    <Table>
                        <TableHead style={{display:'contents'}}>
                            <TableRow>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                Réference 
                                </MDTypography>
                                </TableCell>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                    Type
                                    </MDTypography>
                                </TableCell>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                Prix Details DH(s)/m
                                </MDTypography>
                                </TableCell>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                Prix Grossiste DH(s)/m
                                </MDTypography>
                                </TableCell>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                Disponible m(s)
                                </MDTypography>
                                </TableCell>
                                <TableCell align = 'center'>
                                <MDTypography variant="h6" fontWeight="bold" color="white">
                                    Details
                                  </MDTypography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {searchData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {name, type_tissu, id, prix_detail ,prix_gros,Inventory} = row;
                            let total = Inventory.reduce((total,value)=>{
                              return total + value.quantity
                            },0)

                            return (
                            <TableRow hover key={id} tabIndex={-1}  >
                                <TableCell align = 'center' component="p" scope="row" padding="none"><MDTypography variant="p" fontWeight="regular" color="white">{name}</MDTypography></TableCell>
                                <TableCell align="center"><MDTypography variant="h9" fontWeight="regular" color="white">{type_tissu}</MDTypography></TableCell>
                                <TableCell align="center"><MDTypography variant="h9" fontWeight="regular" color="white">{prix_detail}</MDTypography></TableCell>
                                <TableCell align="center"><MDTypography variant="h9" fontWeight="regular" color="white">{prix_gros}</MDTypography></TableCell>
                                <TableCell align="center"><MDTypography variant="h9" fontWeight="regular" color="white">{Math.round(total * 100) / 100}</MDTypography></TableCell>
                                <TableCell align="center">
                                <MDButton onClick={()=>{navigation(`/produit?id=${id}`)}} variant="gradient" color="info"  size="medium">
                                  <SearchIcon fontSize="large" sx={{color:'white'}}/>
                                </MDButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                <TablePagination
                  style={{color:'white'}}
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={searchData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            
          
          </div>
          
        </div>
      </MDBox>
    </DashboardLayout>
  );
}

export default Tables;
