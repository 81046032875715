/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import { useState ,useEffect} from "react";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { supabaseClient } from "../../supabase";


function Dashboard() {

  const [data,setData] = useState({
    commande : 0,
    charge: 0,
    entree:0,
  })
  
  useEffect(()=>{
    let commande = 0
    let charge = 0
    let entree = 0
    const currentDate = new Date().toISOString().split('T')[0];
    supabaseClient.from("Charges").select('total').gte('created_at',currentDate).then(({data:charges,error})=>{
      charge = charges.reduce((val,total)=> total.total + val,0)
      supabaseClient.from("Orders").select('avance').gte('created_at',currentDate).neq('status','Bloquée').then(({data:Orders,error})=>{
        console.log(Orders)
        let entree = Orders.reduce((val,total)=> total.avance + val,0)
        setData({charge,entree,commande:Orders.length})
      })
      
    })
    
  },[])

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="shopping_cart"
                title="Commandes Du Jour"
                count={`${data.commande}`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="money_off_icon"
                title="Charge Du Jour"
                count={`${data.charge}  DH(s)`}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="attach_money_icon"
                title="Entrée d'Argent"
                count={`${data.entree}  DH(s)`}
                
              />
            </MDBox>
          </Grid>
         
        </Grid>
        
      </MDBox>
      
    </DashboardLayout>
  );
}

export default Dashboard;
