
import { createClient } from '@supabase/supabase-js'

const owner = "b3ce5ff6-16ac-427b-87a2-d5662535f3d2"
const supabaseUrl = "https://eowoxbnqflkekcpqajvg.supabase.co"
const supabaseAnonKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVvd294Ym5xZmxrZWtjcHFhanZnIiwicm9sZSI6ImFub24iLCJpYXQiOjE2ODU3MjQ4ODgsImV4cCI6MjAwMTMwMDg4OH0.PvZWIyb8A1XlrlG1Ikx_QwhQBxIrok8K2ZQr3v1INjI"
//const supabaseServiceKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVvd294Ym5xZmxrZWtjcHFhanZnIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTY4NTcyNDg4OCwiZXhwIjoyMDAxMzAwODg4fQ.OMZY_TPHNCLVtO6QNAJI62te1YZ4GQd2fGrt73VwTMA"
const supabaseClient = createClient(supabaseUrl, supabaseAnonKey)
export  {
  supabaseAnonKey,
  supabaseUrl,
  supabaseClient, 
  owner,
}//const supabase = createClient(supabaseUrl, supabaseAnonKey)

//export const supabaseService = createClient(supabaseUrl,supabaseServiceKey,{auth:{persistSession:false,}}).auth.admin

